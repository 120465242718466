<template>
  <div class="h-100">
    <div v-if="dietPlanImage == null">
      <h3 class="emptyTitle">یکی از برنامه های غذایی را انتخاب کنید</h3>
    </div>
    <div v-else>
      <div id="detailInfo" >
      <img :src="getSrc(image,'Image')"  style="width:100%;height:100%;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";
export default {
  name: "adiveDetail",
  components: {},
  props: ["dietPlanImage"],
  data() {
    return { 
      image: "",
    };
  },
  watch: {
    dietPlanImage: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("dietPlan", ["GetById"]),
    getSrc(image, type) {
      return `${apiUrlRoot}/DietPlan/${type}/${image}`;
    },
    async fetchData() {
      if (this.dietPlanImage != null) {
        this.image = this.dietPlanImage;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.emptyTitle {
  width: 98%;
  text-align: center;
  float: right;
  margin-top: 5px;
  border-bottom: 1px dotted #000;
  padding-bottom: 3px;
  margin-right: 1%;
}
#detailInfo {
  width: 100%;
  padding: 5px;
}

#detailFile {
  width: 100%;
  float: right;
  clear: both;
  background-color: #f5f5f5;
  margin-bottom: 7px;
  box-shadow: 0px 1px 2px 0px #000;
}
#detailFile img {
  width: 170px;
  height: 170px;
  float: right;
  clear: right;
  margin: 3px;
  border-radius: 100%;
}
#detailFile p {
  float: right;
  width: 82%;
  margin-top: 10px;
}
</style>